import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
// import sis_logo from '../../assets/images/projects/service/muvisi/sis-logo.png'
import interparking_logo from '../../assets/images/projects/service/muvisi/interparking-logo.jpg'
import adobelogo from '../../assets/images/projects/service/mercedes/adobe_logo.png'
import pic02 from '../../assets/images/projects/21.png'
import { useRef } from 'react'




const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignMuvisi = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (

  <Layout>
    <Helmet>
      <title>Service Design - Muvisi Private</title>
      <meta name="Service Design - Muvisi Private" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.muvisi_cover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
          <p className="maisontags">Service design, User experience, Interaction design, UX Strategy, Digital UX/UI, Branding</p>

            {/* <span className="tag">SERVICE DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <span className="tag">BRAND IDENTITY</span> */}

            <h1>muvisi.</h1>
            <h3>
            A mobility service that streamlines parking 
            across the entire ecosystem of drivers, operators 
            and municipality. 
            </h3>
            <br />
            <h3>
            For drivers it provides an end-to-end solution through
             reliable parking availability data; which makes it 
             stress-free and convenient to find & navigate to 
             on-street parking spots, track sessions and pay 
             seamlessly for actual parked time. 
            </h3>
            <br />
            <h3>
            Whilst assisting parking operators & cities to 
            manage their assets more effectively. 
            </h3>
          </header>
          <br />


          <section className="header-post">
              <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  *This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello"><u>Get in touch</u></a> with me for more 
                  details related to this project; such as my
                  role, the problems i faced, the processes i 
                  used and how i addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-3" src={things_logo} alt="things logo" />
                </div>
                <br />
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products and services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>            
            </div>
          </section>


          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <p>
                This project is made up of 2 phases.
                </p>
                <p>
                In phase 1 of the project, our scope was to
                consider only the 'on-street' (Blue lines)
                parking  scenario for few selected cities 
                in Italy. 
                </p>
                <p>
                In phase 2, we plan to evolve the service to
                 also include the use case of 'off-street' 
                 parking, while also scaling the service to 
                 even more cities in Italy.  
                </p>
          </div>
          <div className="process-right">
                <h4>
                Project overview
                </h4>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> An urban mobility service that assists users to find parking availability.
                </span>
                <br />
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of
                  THINGS, Milan
                </span>
                <br />
                <span className="credits-text">
                  <strong>MY ROLE:</strong>  Benchmarking competitors, Blue-sky research
                   to gather inspiration from other industries, preparing for on-field 
                   interviews, carrying out service safaris, user-behaviour observation 
                   and interviews, research analysis, brand strategy, experience mapping 
                   front & back office, wireframing digital product, visual design and 
                   prototyping of digital touchpoints.
                </span>
                <div className="challenge-client">
                <br />
                <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-5" src={interparking_logo} alt="bsmart logo" />
                  </div>
                </div>
            </div>
            </div>


          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                Parking is one of the most difficult and dysfunctional parts of Italian city life. 
                </p>
                <p>
                We aimed to solve these parking problems by creating a fully digitalized 
                parking ecosystem that address all sides of the market; drivers (B2C), 
                private parking operators (B2B) and city municipalities as a white-label 
                product (B2G).
                </p>
              </div>


              {/* <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2019
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 5 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> An urban mobility service that
                  assists users to find parking availability..
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Benchmarking competitors, Blue-sky
                  research to gather inspiration from other industries,
                  preparing for on-field interviews, carrying out service
                  safaris, user-behaviour observation and interviews, research
                  analysis, brand strategy, experience mapping front & back
                  office, wireframing digital product, visual design
                  consultation and prototyping of digital touchpoints.
                </span>
                <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <img
                      className="span-6"
                      src={interparking_logo}
                      alt="interparking logo"
                    />
                    <img className="span-4" src={sis_logo} alt="sis logo" />
                  </div>
                </div>
              </div> */}


            </div>
            <div className="image">
              <Img fluid={props.data.muvisichallenge.childImageSharp.fluid} />
            </div>


            {/* <p className="full-text">
              Scope: In phase 1 of the project, we considered only on-street
              parking scenario. The later phases were planned to include
              off-street parking and a multi-modal mobility service to be scaled
              to multiple cities. The challenge was thus to design phase 1 in a
              way that allowed for this evolution & expansion of the service.
            </p> */}

              <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

          </section>


          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>

            <br />
            <br />
            <br />
            <br />
           
            <div className="process-image">
              <Img fluid={props.data.muvisi_process.childImageSharp.fluid} />
            </div>
            
            <div className="changebg">
            <div className="process">           
              <div className="process-left">
              <h4>
              Understanding the current parking landscape
              </h4>
              </div>
              <div className="process-right">
              <p>
              We initiated the project by learning about different 
              on-street parking typologies, their rules & regulations
               set by city municipality.    
              </p>
              <p>
              The city’s parking consists of white lines 
              (free parking), yellow lines (resident parking) 
              and blue lines (paid parking); the later is the 
              only parking typology we considered for phase 1
               of the project (scope). 
              </p>
              <p>
              This followed a benchmarking study to analyse 
              the existing competitors in Italy, Europe and beyond.     
              </p>               
              </div>
              </div>

              <p className="full-textlarge">
              The competitors we identified were mapped across 2 
              independent variables (payment method & parking space
              visibility) to understand their positioning and how 
              we could strategically place ourselves in the market. 
              </p>

              <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.muvisiprocess2.childImageSharp.fluid} />
              <Img fluid={props.data.muvisiprocess3.childImageSharp.fluid} />
              <Img fluid={props.data.muvisiprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.muvisiprocess5.childImageSharp.fluid} />
              <Img fluid={props.data.muvisiprocess6.childImageSharp.fluid} />
              <Img fluid={props.data.muvisiprocess7.childImageSharp.fluid} />
              <Img fluid={props.data.muvisiprocess8.childImageSharp.fluid} />
              </Slider>
              </div>


            <div className="process">           
            <div className="process-left">
            <h4>Getting out onto the streets</h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              It was vital to step into the shoes of the drivers and
               experience first hand what it is like to drive and 
               find parking in the city.   
              </p> 
              <p>
              We carefully selected contexts/scenarios for
              its unique characteristics, which would give
              us as many insights as possible: 
              </p>
              <ol>
                <li>Finding parking in known vs. unknown location</li>
                <li>Finding parking near destination vs. pre-planning route</li>
                <li>Finding parking at different times of the day</li>
                <li>Paying using sms vs. parking meter</li>
              </ol>
              <p>
              We also tried the various apps that we discovered during benchmarking study. 
              </p>          
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess9.childImageSharp.fluid} />
            </div>

            <p className="full-textlarge">
            We learned that finding parking is a stressful situation 
            due to: low availability, uncertainty of spots and time 
            spent to find parking, pressure of surrounding cars & 
            inconvenient payment. 
            </p>

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>Contextual interviews and behavioural observations</h4>
              </div>
              <div className="process-right">
               
              <p>
              Next, we prepared for field research by 
              outlining the goals of user-observation
               & interview questions. 
              </p>
                     
              </div>
            </div>

            <p className="full-textlarge">
            Our goal was to meet with users to learn about their
             habits & behaviours, what drives their current choices 
             when it comes to going from point A to B in the city, 
             what frustrates them, what makes them happy and what 
             would make them change their routine.
            </p>

            <div className="image">
              <Img fluid={props.data.muvisiprocess10.childImageSharp.fluid} />
            </div>


            <div className="process">           
            
              <div className="process-right">
              <p className="half-textlarge">
              Our questions focused on the entire journey of the user.   
              </p> 
              
              <ol>
                <li>Before: planning their upcoming journey, getting in their car, driving to their destination.</li>
                <br />
                <li>During: Arriving near their destination, parking their car.</li>
                <br />
                <li>After: doing their thing, returning to their car, paying for their session, driving off.</li>
              </ol>
                        
              </div>
            </div>


            <p className="full-textlarge">
            "Key information is not clear such as max. allowed
             time to park, parking rules, exceptions and city restrictions."
            </p>
            <p className="full-textlarge">
            "Using the parking meter needs coins/cash. Plus I need to guess how much
             time I will stay there... and If I have to extend I need to come 
             back to my car & parking meter"
            </p>
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Research analysis & insight generation</h4>
              </div>
              <div className="process-right">              
              <p>
              All our research findings was put together for us to
               begin our analysis. We started by making clusters so
                that we could highlight patterns and key elements. 
              </p>    
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess11.childImageSharp.fluid} />
            </div>

            <div className="bluebg">
            <p className="full-textlargebluebg">
            When users are in a particular context/scenario, they have 
            certain jobs they have to do. The way people do these jobs 
            (behaviour) and the needs of the people depend on their 
            mindset and personalities. 
            </p>
            </div>


            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            Based on our interviews and observations we 
            identified 3 different personality traits - mindsets   
            </p> 
            </div>
            <div className="process-right">
              
              <p>
              Our research also allowed us to identify the 
              different scenarios, contexts and Jobs-to-be-done 
              that users have. 
              </p>
              <ol>
                <li>Using an app to find parking  vs. not using any app.</li>
                <li>Going to the same location regularly.</li>
                <li>Going to a location casually.</li>
                <li>Go to new locations or areas they are not familiar with.</li>
                <li>Parking for short vs. long period of time.</li>
              </ol>
                        
              </div>
            </div>


            <div className="image">
              <Img fluid={props.data.muvisiprocess12.childImageSharp.fluid} />
            </div>


            <div className="process">           
            <div className="process-right">
              <p>
              We then extracted insights and identified opportunity 
              areas for our solution. Insights were converted to How 
              Might We statements.  
              </p>         
              </div>
            </div>

            <div className="bluebg">
            <p className="full-textlargebluebg">
            We found out that most car journeys in cities involve parking 
            pain on daily basis; from uncertainty (parking availability, 
            prices, restrictions) to inconvenience (full car parks, time 
            spent to find parking, slow payments, pressure of surrounding 
            cars & unfair fines). 
            </p>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess13.childImageSharp.fluid} />
            </div>

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>UX Stratergy</h4>
              </div>
              <div className="process-right">              
              <p>
              Formulating a UX strategy  involved laying down the
               guiding principles of our brand & its positioning,
                our challenges & desired outcomes, key values, 
                contexts & users we would focus on, short-long 
                term goals, KPIs, and service evolution strategy.  
              </p>    
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess14.childImageSharp.fluid} />
            </div>
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>Experience design of mobile app</h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              The identified strategy, research insights and 
              opportunities were used to outline the overall 
              experience we wanted to offer to users and the 
              back-office stakeholders.    
            </p>              
              <p>
              Consolidation of various functionalities and 
              its prioritisation based on user needs, 
              business goals & project timeline was 
              carried out.  
              </p> 
              <p>
              This work formed the basis for mapping the end-to-end 
              service; that included both online & offline user
               experience and its corresponding technical requirement 
               in the back-end.   
              </p>    
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess15.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-right">
              <p>
              We faced many challenges from technical constraints, 
              business requirements to city regulations and after a
               few discussions with clients and iterations we started 
               making wireframes of the mobile app.   
              </p>         
              </div>
            </div>


            <p className="full-textlarge">
            Low-fidelity wireframing of key interactions and 
            functionalities allowed us to quickly test various 
            alternatives and consolidate the experience. 
            </p>

            <div className="changebg">
            <div className="process">           
            <div className="process-left">
            <h4>Experience design of back-office system</h4>
              </div>
              <div className="process-right">              
              <p>
              Based on the functionalities & user flow of the front-office, the back-office features were defined.  
              </p>    
              </div>
            </div>

            <p className="full-textlarge">
            The back-office was to designed to be an operational and analytical
             dashboard that would give insights to respective stakeholders so 
             as to take data informed decisions on how to further optimise the
              mobility, in terms of traffic and vehicle flow in the city.
            </p>

            <div className="process">           
            <div className="process-right">
              <p>
              A hierarchy system depending on their roles, skills, 
              level of visibility and control in the back-office system was created.    
              </p>         
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess26.childImageSharp.fluid} />
            </div>

            <p className="full-textlarge">
            For each stakeholder we understood their access restrictions, jobs-to-do, goals and
             needs while also taking into account their technical knowledge and familiarity with the parking system. 
            </p>

            <div className="process">           
            <div className="process-right">
              <p>
              This allowed us to map out the touchpoint flows for various use-cases. We then 
              created wireframes of various sections and prioritised the layout according 
              to information hierarchy based on each stakeholder's needs.    
              </p>         
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess27.childImageSharp.fluid} />
            </div>
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>Brand personality & visual language</h4>
              </div>
              <div className="process-right">              
              <p>
              Next, we defined our strategy to ensure that 
              the business vision, user needs, and technical 
              capabilities are aligned to our priorities & 
              focus on delivering the best possible experience
               in the areas that matter the most to our users.   
              </p>    
              </div>
            </div>

            <p className="full-textlarge">
            We came up a visual concept that was calm & 
            minimal while at the same time stood out for
             its futuristic elements; this also tied-in 
             perfectly with the overall values of our service.
            </p>

            <div className="image">
              <Img fluid={props.data.muvisiprocess35.childImageSharp.fluid} />
            </div>

            <div className="image">
              <Img fluid={props.data.muvisiprocess36.childImageSharp.fluid} />
            </div>
 
          </section>







          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>

              <div className="outcome">
              <div className="outcome-right">
                <p>
                We have designed a suite of fully integrated and 
                comprehensive parking management solution for Drivers, 
                City Municipalities (as white-label) and Private operators 
                to manage their parking assets.  
                </p>
              </div>
              </div>

              <h3>
              Muvisi mobile app helps users simplify their parking 
              experience by leveraging on various data sources and IoT technology.   
              </h3>
              <h3>
              The back-office system & dashboard provides a macro-view of the 
              entire parking ecosystem bringing insights into parking behavior
               so that quick data-informed decisions can be made.   
              </h3>





              {/* <p className="full-text">
                We have designed a suite of fully integrated and comprehensive
                parking management solution for Drivers, City Municipalities (as
                white-label) and Private operators to manage their parking
                assets.
              </p>
              <h3>
                Muvisi helps users simplify their parking experience by
                leveraging on various data sources and IoT technology. Real-time
                parking availability info is represented as relatable "mins to
                find parking" data. Whereas the "Best-route" feature reduces
                parking search time & effort as it takes the optimised route to
                their destination passing through different parking areas.
              </h3>
              <h3>
                The back-office system & dashboard provides a macro-view of the
                entire parking ecosystem bringing insights into parking behavior
                so that quick data-informed decisions can be made.
              </h3>
              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    Back-office stakeholders can track various data parameters;
                    in real-time or selected time period. The dashboard UX
                    provides necessary information at a glace so that analysis
                    paralysis is avoided; improving their workflow.
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    The timelapse section captures navigational information in a
                    video format that give insights on busy routes, popular
                    parking locations and top POI destinations.
                  </p>
                </div>
              </div>
              <p className="full-text">
                The product was first currently launched in the market as a
                pilot for 3 month in the Italian Province of Pesaro and Urbino.
                We aimed to collect feedback during the pilot study to
                consolidate the on-street parking service. At the same time,
                evolve our service offering by adding off-street parking garages
                to the Muvisi ecosystem.
              </p>
              <p className="full-text">
                In the final phase, our goal is to further evolve the service
                into a multi-modal transport ecosystem by integrating with
                public & private modalities such as trains, metros, buses and
                floating transportation.
              </p> */}

              <p>
                Visit <a href="https://muvisi.app">muvisi.app</a> to find out
                more.
              </p>
            </div>
          </section>


          <section className="process-section">
          <div className="process">           
            <div className="process-left">
            <h4>Pilot launch</h4>
              </div>
              <div className="process-right">              
              <p>
              The product was planned to be launched in the market 
              as a pilot for 3 month in the Italian Province of Pesaro 
              and Urbino. However, due to COVID-19 this was put on hold.   
              </p>
              <p>
              We aimed to collect feedback during the pilot study to 
              consolidate the on-street parking service. At the same 
              time, evolve our service offering by adding off-street
               parking garages to the Muvisi ecosystem.    
              </p>     
              </div>
            </div>
          </section>


        </div>
      </div>


      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.muvisioutcome40.childImageSharp.fluid} />
        </div>
        <div className="image">
          <Img fluid={props.data.muvisioutcome41.childImageSharp.fluid} />
        </div>
      </section>

      {/* remove these breaks below when the prototype code lines are un-commented */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />




                  {/* commented the below prototype link section till the prototype is made */}

      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
          <div className="changebg">
          <br />
          <br />
          <div className="process">           
            <div className="process-left">
              <h4>
              Prototype of the mobile and web app
              </h4>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={adobelogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              See Prototype
              </a>
              </div> 
                             
              </div>
            </div>  
          </div>
      </section>
      </div>
      </div> */}




      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.muvisioutcome42.childImageSharp.fluid} />
        </div>
      </section>




      <div className="project-wrapper">
          <div className="project-content">
          {/* <section className="process"> */}
          <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              We found out from our testing that it was easy for users to do 
              certain things on the app, only to realise in the next moment
               that they didn’t want to make that decision or have changed their mind. 
              </p> 
              <p>
              We solved this problem by first identifying points in the 
              interaction that required users to make a conscious decision
               and then introduced intentional friction (animations & slide 
               interaction) in the experience so that they would be sure of the
                decision they were making.  
              </p>               
              </div>
            </div>
            

            <div className="process">           
              <div className="process-right">
              <p>
              Quantitative data and field research had given us couple 
              of important insights which proved our initial assumptions wrong.
              </p> 
              <p>
              We (design team) convinced the other teams (business, front & 
              back-end technical) the necessity of changing some features and
               including new features in the service; whilst balancing the effort, 
               complexity and cost.
              </p>               
              </div>
            </div>

            <div className="process">           
              <div className="process-right">
              <p>
              We used Google APIs for our maps to ‘call’ for Point of Interest (POI) 
              information when users filter or search for a POI, where each 
              ‘call’ incurred certain cost for us. This wasn’t business
               viable considering the use cases and behaviours users 
               had on our app.
              </p> 
              <p>
              We solved this by making a single ‘call’ where we got all 
              the POI information, but only the filter or information that 
              was searched by the user was shown to them. So the next time 
              when the user searched or filtered another POI, that POI could 
              be shown to user without making another API call 
              </p>               
              </div>
            </div>

            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              Difficult to manage the launch of a pilot    
              </p> 
              <p>
              Development of the best route deature could 
              have been design in a MVP manner. This costs 
              us lots of time and effort which could have been avoided. 
              </p>                
              </div>
            </div>
          </div>
          <br />
          
          
          <p className="full-text">
          Thank you & park stress-free! <span role="img" aria-labelledby="muvisiemoji">🚗😅</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


          {/* </section> */}
        </div>
      </div>



      <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-mercedes">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Bringing a human-centric approach to privacy control 
                  & data transparency of IoT products at home.
                    </div>
                    <br />
                    <p className="maisontags-card">Research, User Experience, Digital UX/UI, Interaction design</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic02} alt="" />
                </div>
              </a>
            </div>
      </section>



    


      {/* <section id="footertopcta" className="inner">
        <p className="text arrow-front">
          check out my
          <br />
          <Link to="/#one">featured work.</Link>
        </p>
      </section> */}
    {/* <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-tecne">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">tecne.</div>
                <div className="text">
                  Next gen combat training with an underglove fitted with
                  sensors & an app that gives precise feedback to improve.
                </div>
                <div className="tags">
                  <ul>
                    <li>UX STRATEGY</li>
                    <li>INTERACTION DESIGN</li>
                    <li>DIGITAL UX/UI</li>
                    <li>PRODUCT DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-2">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 2).</div>
                <div className="text">
                  A mobile application to find & book food experiences; and a
                  backend dashboard for management of listings.
                </div>
                <div className="tags">
                  <ul>
                    <li>UX STRATEGY</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> */}




    </div>
  </Layout>
)
}

export default ServiceDesignMuvisi

export const fluidMuvisiImage = graphql`
  fragment fluidMuvisiImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    muvisi_cover: file(
      relativePath: {
        eq: "projects/service/muvisi/cover/muvisi-cover-image.jpg"
      }
    ) {
      ...fluidMuvisiImage
    }
    interparking_logo: file(
      relativePath: { eq: "projects/service/muvisi/interparking-logo.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    sis_logo: file(
      relativePath: { eq: "projects/service/muvisi/sis-logo.png" }
    ) {
      ...fluidMuvisiImage
    }
    things_logo: file(
      relativePath: { eq: "projects/service/tech/things-logo.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisichallenge: file(
      relativePath: { eq: "projects/service/muvisi/challenge/1.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess2: file(
      relativePath: { eq: "projects/service/muvisi/process/2.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess3: file(
      relativePath: { eq: "projects/service/muvisi/process/3.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess4: file(
      relativePath: { eq: "projects/service/muvisi/process/4.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess5: file(
      relativePath: { eq: "projects/service/muvisi/process/5.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess6: file(
      relativePath: { eq: "projects/service/muvisi/process/6.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess7: file(
      relativePath: { eq: "projects/service/muvisi/process/7.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess8: file(
      relativePath: { eq: "projects/service/muvisi/process/8.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess9: file(
      relativePath: { eq: "projects/service/muvisi/process/9.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess10: file(
      relativePath: { eq: "projects/service/muvisi/process/10.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess11: file(
      relativePath: { eq: "projects/service/muvisi/process/11.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess12: file(
      relativePath: { eq: "projects/service/muvisi/process/12.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess13: file(
      relativePath: { eq: "projects/service/muvisi/process/13.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess14: file(
      relativePath: { eq: "projects/service/muvisi/process/14.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess15: file(
      relativePath: { eq: "projects/service/muvisi/process/15.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess16: file(
      relativePath: { eq: "projects/service/muvisi/process/16.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess17: file(
      relativePath: { eq: "projects/service/muvisi/process/17.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess18: file(
      relativePath: { eq: "projects/service/muvisi/process/18.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess19: file(
      relativePath: { eq: "projects/service/muvisi/process/19.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess20: file(
      relativePath: { eq: "projects/service/muvisi/process/20.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess21: file(
      relativePath: { eq: "projects/service/muvisi/process/21.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess22: file(
      relativePath: { eq: "projects/service/muvisi/process/22.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess23: file(
      relativePath: { eq: "projects/service/muvisi/process/23.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess24: file(
      relativePath: { eq: "projects/service/muvisi/process/24.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess25: file(
      relativePath: { eq: "projects/service/muvisi/process/25.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess26: file(
      relativePath: { eq: "projects/service/muvisi/process/26.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess27: file(
      relativePath: { eq: "projects/service/muvisi/process/27.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess28: file(
      relativePath: { eq: "projects/service/muvisi/process/28.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess29: file(
      relativePath: { eq: "projects/service/muvisi/process/29.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess30: file(
      relativePath: { eq: "projects/service/muvisi/process/30.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess31: file(
      relativePath: { eq: "projects/service/muvisi/process/31.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess32: file(
      relativePath: { eq: "projects/service/muvisi/process/32.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess33: file(
      relativePath: { eq: "projects/service/muvisi/process/33.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess34: file(
      relativePath: { eq: "projects/service/muvisi/process/34.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess35: file(
      relativePath: { eq: "projects/service/muvisi/process/35.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess36: file(
      relativePath: { eq: "projects/service/muvisi/process/36.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess37: file(
      relativePath: { eq: "projects/service/muvisi/process/37.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess38: file(
      relativePath: { eq: "projects/service/muvisi/process/38.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisiprocess39: file(
      relativePath: { eq: "projects/service/muvisi/process/39.jpg" }
    ) {
      ...fluidMuvisiImage
    }
    muvisioutcome40: file(
      relativePath: { eq: "projects/service/muvisi/outcome/40.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisioutcome41: file(
      relativePath: { eq: "projects/service/muvisi/outcome/41.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisioutcome42: file(
      relativePath: { eq: "projects/service/muvisi/outcome/42.png" }
    ) {
      ...fluidMuvisiImage
    }
    muvisi_process: file(
      relativePath: { eq: "projects/process/muvisi-process.png" }
    ) {
      ...fluidMuvisiImage
    }
    project1: file(relativePath: { eq: "projects/15.png" }) {
      ...fluidMuvisiImage
    }
    project2: file(relativePath: { eq: "projects/4.png" }) {
      ...fluidMuvisiImage
    }
  }
`
